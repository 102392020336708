import { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

import { isAppleMobileDevice } from '@/infra/isAppleMobileDevice'
import Plus from '@/presentation/assets/svg/Plus.svg'
import TrashIcon from '@/presentation/assets/svg/TrashIcon.svg'
import Button from '@/presentation/components/Button'
import DevicePermissionPopup from '@/presentation/components/DevicePermissionPopup'
import FormImage from '@/presentation/components/FormImage'
import Header from '@/presentation/components/Header'
import ConfirmDeleteDivergencePopup from '@/presentation/pages/Divergence/ConfirmDeleteDivergencePopup'
import { permissionPopupStorageKey } from '@/services/constants/localStorageKeys'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import { Status, useDivergence } from '@/services/providers/DivergenceContext'
import { usePreview } from '@/services/providers/PreviewContext'
import { useRoom } from '@/services/providers/RoomContext'
import validateFileType from '@/services/utils/validateFileType'

const DivergenceRegisterPage: React.FC = () => {
  const { t } = useTranslation()

  const [showConfirmDeleteDivergencePopup, setConfirmDeleteDivergencePopup] =
    useState(false)
  const [showDevicePermissionPopup, setShowDevicePermissionPopup] =
    useState(false)
  const [isCreatingTheDivergence, setIsCreatingTheDivergence] = useState(false)

  const navigate = useNavigateWithOrderCode()

  const { previews, setPreviews, thereIsAnImageBeingUploaded } = usePreview()
  const {
    divergences,
    divergenceInProcess,
    makeDivergence,
    removeDivergencePhotoByFilePath,
    removeDivergence,
    status
  } = useDivergence()
  const { rooms } = useRoom()

  const imageInputRef = useRef<HTMLInputElement>(null)

  const [description, setDescription] = useState(
    divergenceInProcess?.description || ''
  )
  const [errors, setErrors] = useState<{ [key: string]: string }>()

  const room = rooms.find(room => room.id === divergenceInProcess?.roomId)

  const item = room?.items.find(item => item.id === divergenceInProcess?.itemId)

  const handleVerifyIfFileIsEmpty = (file: File) => {
    if (!file) return true

    return Object.keys(file).length === 0 && file.constructor === Object
  }

  const handleAddImage = () => {
    if (isAppleMobileDevice()) {
      const permissionPopupAlreadySeen = localStorage.getItem(
        permissionPopupStorageKey
      )

      if (permissionPopupAlreadySeen !== 'true') {
        setShowDevicePermissionPopup(true)

        return
      }
    }

    imageInputRef.current?.click()
  }

  const handleDevicePermissionPopupClose = () => {
    localStorage.setItem(permissionPopupStorageKey, 'true')

    setShowDevicePermissionPopup(false)

    handleAddImage()
  }

  const handleImageAdded = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files && files?.length > 0) {
      const [file] = files

      if (!validateFileType(file)) {
        setErrors(state => ({
          ...state,
          previews: t('INVALID_IMAGE', 'A imagem enviada é inválida!')
        }))

        return
      }

      setPreviews(state => [
        ...state,
        {
          id: uuidV4(),
          file,
          filePath: '',
          hasRemotelyUploaded: false,
          uploadProgress: 0
        }
      ])
    }
  }

  const handleRemoveImage = (
    indexToRemove: number,
    filePathToRemove?: string
  ) => {
    if (previews.length - 1 === 0) {
      const errorMessage = t(
        'NEED_AT_LEAST_ONE_PHOTO_ATTACHED',
        'É necessário que ao menos uma foto esteja anexada'
      )

      setErrors(state => ({ ...state, previews: errorMessage }))

      return
    }

    setPreviews(state => state.filter((_, index) => index !== indexToRemove))

    filePathToRemove && removeDivergencePhotoByFilePath(filePathToRemove)
  }

  const handleConfirmDivergence = async () => {
    setErrors({})

    const actualErrors: { [key: string]: string } = {}

    if (!description) {
      actualErrors.description = t(
        'NEED_TO_DETAIL_CONTESTATION_TO_CONTINUE',
        'É necessário detalhar a contestação para continuar'
      )
    }

    if (!previews?.length) {
      actualErrors.previews = t(
        'NEED_AT_LEAST_ONE_PHOTO_TO_CONTINUE',
        'É necessário ao menos uma foto para continuar'
      )
    }

    if (Object.keys(actualErrors).length > 0) {
      setErrors(actualErrors)

      return
    }

    try {
      setIsCreatingTheDivergence(true)

      if (room && item) {
        const uploadPhotoError = await makeDivergence({
          roomId: room?.id,
          itemId: item?.id,
          description,
          photos: previews,
          extra: item
        })

        if (!uploadPhotoError) navigate(ROUTES.DIVERGENCE_LIST)

        setErrors({
          previews: t(
            'ERROR_TO_UPLOAD_A_PHOTO',
            'Houve um erro ao enviar uma foto, tente novamente anexar as fotos'
          )
        })
      }
      setErrors({
        previews: t(
          'UNEXPECTED_ERROR_CONTESTATION',
          'Houve um erro inesperado, tente novamente mais tarde'
        )
      })
    } catch (err) {
      console.error('DivergenceRegisterPage.handleConfirmDivergence error', err)
      setErrors({
        previews: t(
          'UNEXPECTED_ERROR_CONTESTATION',
          'Houve um erro inesperado, tente novamente mais tarde'
        )
      })
    } finally {
      setIsCreatingTheDivergence(false)
    }
  }

  const handleRemoveDivergece = () => {
    removeDivergence(room?.id as string, item?.id as string)

    navigate(ROUTES.DIVERGENCE_LIST)
  }

  useEffect(() => {
    if (!room) {
      navigate(ROUTES.MAIN)
    }

    if (!item) {
      navigate(ROUTES.ROOMS)
    }
  }, [item, navigate, room])

  useEffect(() => {
    setPreviews(
      divergenceInProcess?.photos?.map(photo => ({
        ...photo,
        uploadProgress: 0
      })) || []
    )
  }, [])

  const isDivergenceAlreadyRegistered = divergences.some(divergence => {
    return divergence?.roomId === room?.id && divergence?.itemId === item?.id
  })

  window.onbeforeunload = function () {
    return t(
      'ARE_YOU_SURE_WANT_TO_EXIT_CONTESTATIONS',
      'Você tem certeza que quer sair? Todo progresso de contestações será perdido!'
    )
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        title={t('CONTEST', 'Contestar')}
        deleteDivergenceButton={
          status === Status.AVAILABLE &&
          isDivergenceAlreadyRegistered && (
            <img
              className="w-5 cursor-pointer"
              src={TrashIcon}
              onClick={() => setConfirmDeleteDivergencePopup(true)}
            />
          )
        }
      />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        <h1 className="mb-6 w-full text-3xl font-bold text-left text-primary">
          {t(
            'REGISTER_YOUR_CONTESTATION_HERE',
            'Registre aqui sua contestação'
          )}
        </h1>

        <span className="block mb-2 w-full text-left text-gray-500">
          {t('SELECTED_ITEM', 'Item selecionado')}
        </span>

        <div className="box-border flex flex-col mb-4 w-full bg-white rounded-xl border border-gray-400 shadow-md">
          <div
            className="w-full h-20 bg-center bg-no-repeat bg-cover rounded-t-md"
            style={{ backgroundImage: `url(${item?.image})` }}
          />

          <div className="flex flex-col gap-y-2 justify-start items-start p-4 text-left rounded-b-xl border-t-0">
            <span className="text-xl font-semibold text-gray-600">
              {item?.id === 'building'
                ? item?.name
                : `${item?.name} | ${room?.name}`}
            </span>

            {item?.observation && (
              <span className="text-sm text-gray-500">{item?.observation}</span>
            )}
          </div>
        </div>

        <span className="block mb-2 w-full text-left text-gray-500">
          {t(
            'DESCRIBE_YOUR_CONTESTATION_IN_DETAIL',
            'Descreva detalhadamente sua contestação'
          )}
        </span>

        <textarea
          className="block p-2 w-full min-h-[122px] text-sm rounded-lg border-[1.5px] border-gray-400 focus:border-primary focus:outline-none shadow-md"
          placeholder={t(
            'EXAMPLE_PROBLEM_WITH_BATHROOM_FAUCET',
            'Ex: torneira do banheiro também esta com um problema'
          )}
          value={description}
          onChange={v => setDescription(v.currentTarget.value)}
        />

        <span className="block mt-1 w-full text-left text-error">
          {errors?.description}
        </span>

        <span className="block mt-4 mb-2 w-full text-left text-gray-500">
          {t('PHOTOS', 'Fotos')}
        </span>

        <div className="flex flex-wrap gap-4 justify-start items-center w-full">
          <FormImage
            image={
              !handleVerifyIfFileIsEmpty(previews[0]?.file)
                ? previews[0]?.file
                : previews[0]?.filePath
            }
            uploadProgress={previews[0]?.uploadProgress}
            handleClickWithoutImage={handleAddImage}
            handleClickWithImage={() =>
              handleRemoveImage(0, previews[0]?.filePath)
            }
            hasRemotelyUploaded={previews[0]?.hasRemotelyUploaded}
          />

          <FormImage
            image={
              !handleVerifyIfFileIsEmpty(previews[1]?.file)
                ? previews[1]?.file
                : previews[1]?.filePath
            }
            uploadProgress={previews[1]?.uploadProgress}
            handleClickWithoutImage={handleAddImage}
            handleClickWithImage={() =>
              handleRemoveImage(1, previews[1]?.filePath)
            }
            hasRemotelyUploaded={previews[1]?.hasRemotelyUploaded}
          />

          <FormImage
            image={
              !handleVerifyIfFileIsEmpty(previews[2]?.file)
                ? previews[2]?.file
                : previews[2]?.filePath
            }
            uploadProgress={previews[2]?.uploadProgress}
            handleClickWithoutImage={handleAddImage}
            handleClickWithImage={() =>
              handleRemoveImage(2, previews[2]?.filePath)
            }
            hasRemotelyUploaded={previews[2]?.hasRemotelyUploaded}
          />

          {previews.slice(3).map((preview, index) => (
            <FormImage
              key={preview.id}
              image={
                !handleVerifyIfFileIsEmpty(preview?.file)
                  ? preview?.file
                  : preview?.filePath
              }
              uploadProgress={preview?.uploadProgress}
              handleClickWithoutImage={handleAddImage}
              handleClickWithImage={() =>
                handleRemoveImage(index + 3, preview?.filePath)
              }
              hasRemotelyUploaded={preview?.hasRemotelyUploaded}
            />
          ))}

          {previews?.length < 5 && (
            <div
              onClick={handleAddImage}
              className="flex justify-center items-center w-[72px] h-[72px] bg-white rounded-lg border-[1.5px] border-gray-400 cursor-pointer"
            >
              <img src={Plus} />
            </div>
          )}
        </div>

        <input
          ref={imageInputRef}
          onChange={handleImageAdded}
          type="file"
          accept="image/jpg;image/jpeg;image/bmp;image/png;image/gif;capture=camera"
          className="hidden"
        />

        <span className="block mt-1 w-full text-left text-error">
          {errors?.previews}
        </span>

        {status === Status.AVAILABLE && (
          <Button
            className="mt-8 mb-4 w-full max-w-sm"
            onClick={handleConfirmDivergence}
            disabled={thereIsAnImageBeingUploaded || isCreatingTheDivergence}
          >
            {t('REGISTER', 'Registrar')}
          </Button>
        )}
      </div>

      {showConfirmDeleteDivergencePopup && (
        <ConfirmDeleteDivergencePopup
          onCancel={() => setConfirmDeleteDivergencePopup(false)}
          onConfirmButton={() => handleRemoveDivergece()}
        />
      )}

      {showDevicePermissionPopup && (
        <DevicePermissionPopup onCancel={handleDevicePermissionPopupClose} />
      )}
    </div>
  )
}

export default DivergenceRegisterPage
