function validateFileType(file: File) {
  const fileName = file.name
  const idxDot = fileName.lastIndexOf('.') + 1
  const extFile = fileName.substring(idxDot).toLowerCase()

  if (
    extFile == 'jpg' ||
    extFile == 'jpeg' ||
    extFile == 'png' ||
    extFile == 'bmp' ||
    extFile == 'gif'
  ) {
    return true
  } else {
    return false
  }
}

export default validateFileType
