import { useTranslation } from 'react-i18next'

import X from '@/presentation/assets/svg/X.svg'
import Button from '@/presentation/components/Button'

interface ConfirmDivergencesModalProps {
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmDivergencesModal = (props: ConfirmDivergencesModalProps) => {
  const { onConfirm, onCancel } = props

  const { t } = useTranslation()

  const handleConfirm = () => onConfirm()
  const handleClose = () => onCancel()

  return (
    <div
      onClick={handleClose}
      className="flex fixed inset-0 z-50 justify-center items-center px-4 bg-[#70707059]"
    >
      <div
        className="flex flex-col p-4 max-w-[460px] bg-white rounded-lg"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-1">
          <span className="text-lg text-gray-700">
            {t('ARE_YOU_SURE', 'Tem certeza?')}
          </span>

          <img src={X} onClick={handleClose} className="cursor-pointer" />
        </div>

        <hr className="mb-4" />

        <span className="mb-6 text-gray-700">
          {t(
            'YOU_CAN_MAKE_THIS_SUBMISSION_ONLY_ONCE',
            'Você poderá realizar este envio'
          )}{' '}
          <span className="font-bold text-error">
            {t('ONLY_ONCE', 'somente uma vez')}
          </span>
          .{' '}
          {t(
            'BEFORE_PROCEEDING_YOU_CAN_GO_BACK',
            'Antes de prosseguir você ainda pode voltar e registrar contestações de outros itens.'
          )}
          <br />
          <span className="block mt-4">
            {t('WANT_TO_CONTINUE', 'Deseja continuar?')}
          </span>
        </span>

        <Button className="mb-4" onClick={handleConfirm}>
          {t('PROCEED', 'Prosseguir')}
        </Button>

        <Button onClick={handleClose} styleType="outline" colored>
          {t('RETURN', 'Voltar')}
        </Button>
      </div>
    </div>
  )
}

export default ConfirmDivergencesModal
