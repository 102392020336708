import React from 'react'

import { Provider, ErrorBoundary } from '@rollbar/react'
import ReactDOM from 'react-dom/client'

import '@material/react-linear-progress/dist/linear-progress.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'tailwindcss/tailwind.css'

import '@/infra/i18n/i18n'
import '@/index.css'

import App from '@/App'
import config from '@/config'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const rollbarConfig = {
  accessToken: '647b1a45d8ab40549e7179642fbe97db',
  environment: config.ENV
}

root.render(
  <React.StrictMode>
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>
)
