import { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import ArrowRight from '@/presentation/assets/svg/ArrowRight.svg'
import Button from '@/presentation/components/Button'
import Header from '@/presentation/components/Header'
import ConfirmDivergencesModal from '@/presentation/pages/DivergenceList/ConfirmDivergencesModal'
import RequestStatus from '@/presentation/pages/DivergenceList/RequestStatus'
import { ROUTES } from '@/services/constants/routes'
import useNavigateWithOrderCode from '@/services/hooks/useNavigateWithOrderCode'
import {
  Divergence,
  ReviewStatus,
  Status,
  useDivergence
} from '@/services/providers/DivergenceContext'
import { useRoom } from '@/services/providers/RoomContext'

const DivergenceItemsListPage: React.FC = () => {
  const [itemsWithRoom, setItemsWithRoom] = useState<Divergence[]>([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { t } = useTranslation()

  const { startNewDivergence, divergences, status, reports, reviewStatus } =
    useDivergence()
  const { rooms } = useRoom()

  const navigate = useNavigateWithOrderCode()

  const mappedDivergenceItems = useMemo(() => {
    if (!itemsWithRoom?.length) return []

    return itemsWithRoom.map(divergence => {
      const room = rooms.find(room => room?.id === divergence?.roomId)
      const item = room?.items.find(item => item?.id === divergence?.itemId)

      return {
        ...divergence,
        room,
        item
      }
    })
  }, [itemsWithRoom])

  const divergenceReportDownloadLink = useMemo(() => {
    return reports.find(report =>
      ['divergence_v2', 'full_divergence'].includes(report?.type)
    )?.file
  }, [reports])

  const showDivergenceRequestStatusAndReportLink =
    status !== Status.AVAILABLE &&
    reviewStatus === ReviewStatus.DONE &&
    divergenceReportDownloadLink

  const handleGoToRoomItem = (roomId: string, itemId: string) => {
    if (status === Status.AVAILABLE) {
      startNewDivergence(roomId, itemId)
      navigate(ROUTES.DIVERGENCE_FORM)

      return
    }

    navigate(`${ROUTES.SENT_ITEM}/${roomId}/${itemId}`)
  }

  const handleNewDivergence = () => navigate(ROUTES.ROOMS)

  const handleGoToStart = () => navigate(ROUTES.MAIN)

  const handleDownloadReport = () => {
    window.open(divergenceReportDownloadLink, '_blank')
  }

  useEffect(() => {
    setItemsWithRoom(() => {
      return divergences.filter(divergence => !!divergence?.roomId)
    })
  }, [divergences, rooms])

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Header
        previousRoute={status !== Status.AVAILABLE ? ROUTES.ROOMS : undefined}
        title={t('REGISTERED_CONTESTATIONS', 'Contestações registradas')}
      />

      <div className="flex flex-col items-center py-2 px-4 w-full max-w-[682px]">
        {status !== Status.AVAILABLE && <RequestStatus />}

        {showDivergenceRequestStatusAndReportLink && (
          <Button
            className="mb-8 w-full max-w-xs"
            onClick={() => handleDownloadReport()}
          >
            {t('ACCESS_REPORT', 'Acessar relatório')}
          </Button>
        )}

        <div className="flex flex-col gap-2 items-center mb-6 w-full">
          <h2 className="block mb-2 w-full text-lg text-left text-gray-500">
            {t('CONTESTED_ITEMS', 'Itens contestados')}
          </h2>

          {mappedDivergenceItems.map(divergenceItem => (
            <div
              key={divergenceItem?.roomId + divergenceItem?.itemId}
              className="flex flex-col mb-4 w-full bg-white rounded-xl border border-gray-400 shadow-md cursor-pointer"
              onClick={() =>
                handleGoToRoomItem(
                  divergenceItem?.roomId,
                  divergenceItem?.itemId
                )
              }
            >
              <div
                className="w-full h-20 bg-center bg-no-repeat bg-cover rounded-t-md"
                style={{
                  backgroundImage: `url(${divergenceItem?.item?.image})`
                }}
              />

              <div className="flex flex-col gap-y-2 justify-start items-start p-4 text-left rounded-b-xl border-t-0">
                <div className="flex gap-y-2 justify-between items-center w-full">
                  <span className="text-xl font-semibold text-gray-600">
                    {divergenceItem?.item?.id === 'building'
                      ? divergenceItem?.item?.name
                      : `${divergenceItem?.item?.name} | ${divergenceItem?.room?.name}`}
                  </span>

                  <img src={ArrowRight} alt="Arrow right icon" />
                </div>

                <span className="text-sm text-gray-500">
                  {divergenceItem?.description}
                </span>
              </div>
            </div>
          ))}
        </div>

        {status === Status.AVAILABLE && (
          <>
            <div
              className="flex flex-col items-center p-4 mb-10 w-full max-w-xs font-bold text-primary rounded-lg border border-primary cursor-pointer"
              onClick={handleNewDivergence}
            >
              <span className="text-lg">+</span>

              <span>{t('NEW_CONTESTATION', 'Nova contestação')}</span>
            </div>

            <Button
              className="mb-4 w-full max-w-sm"
              onClick={() => setShowConfirmationModal(true)}
              disabled={!divergences.length}
            >
              {t('SEND', 'Enviar')}
            </Button>
          </>
        )}

        <Button
          className="mb-4 w-full max-w-sm"
          styleType="outline"
          onClick={handleGoToStart}
          colored
        >
          {t('RETURN_TO_START', 'Voltar ao início')}
        </Button>
      </div>

      {showConfirmationModal && (
        <ConfirmDivergencesModal
          onConfirm={() => navigate(ROUTES.REGISTER_COMPLETE)}
          onCancel={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  )
}

export default DivergenceItemsListPage
